<template>
  <div>
    <b-modal id="modal-create-status" :no-close-on-backdrop="true" :no-close-on-esc="true" hide-footer centered title="Create Status">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(saveStatus)">
          <b-form-group label="Status Name*" label-for="h-name">
            <validation-provider name="Status Nane" #default="{ errors }" rules="required">
              <b-form-input v-model="statusName" size="sm" id="h-name" placeholder="Name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <app-color-palette-vue @update:selected-color="colorSelectedHandler" :row-number="8"></app-color-palette-vue>

          <div class="d-flex justify-content-end mt-2">
            <b-button variant="primary" type="submit">
              Add
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,

    //Component
    AppColorPaletteVue,
    // Form Validation

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      statusName: null,

      selectedTextColor: '',
      selectedBgColor: '',
    };
  },

  created() {},

  props: {
    leadStatuses: Array,
  },

  methods: {
    saveStatus() {
      const cleanedStatusName = this.cleanAndNormalizeText(this.statusName);
      const isDuplicate = this.leadStatuses.some((filter) => this.cleanAndNormalizeText(filter.title) === cleanedStatusName);
      if (isDuplicate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            text: 'Section with the same name already exists.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        return;
      } else {
        store
          .dispatch('lead/saveStatus', { title: this.statusName, textColor: this.selectedTextColor, bgColor: this.selectedBgColor })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Section was added successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            this.$emit('status-added');
            this.$refs.refFormObserver.reset();
            this.statusName = null;
            this.$bvModal.hide('modal-create-status');
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
    },

    colorSelectedHandler(textColor, bgColor) {
      this.selectedTextColor = textColor;
      this.selectedBgColor = bgColor;
    },

    cleanAndNormalizeText(text) {
      return text
        .replace(/\s+/g, ' ')
        .trim()
        .toLowerCase(); // Boşlukları düzenle ve küçük harfe çevir
    },
  },
};
</script>
