<template>
  <div>
    <b-modal id="modal-manage-status" :no-close-on-backdrop="true" :no-close-on-esc="true" hide-footer centered title="Manage Sections">
      <b-list-group class="list-group-filters">
     

          <b-list-group-item v-for="filter in leadStatuses" :key="filter.title" >
            <div v-if="!filter.editing">

              <feather-icon :icon="filter.icon" size="18" class="mr-75" />
            <span class="align-text-bottom line-height-1">{{ filter.title }}</span>

            <div v-if="filter.title!='Chasing'" class="close-button" @click="editStatus(filter)" style="cursor: pointer; position: absolute; top: 7px; right: 25px;">
              <feather-icon icon="EditIcon" size="14" class="mr-75" />
          </div>

            <div v-if="filter.title!='Chasing'" class="close-button" @click="removeStatus(filter)" style="cursor: pointer; position: absolute; top: 0px; right: 10px;">
            <span style="font-size: 2em; color:red">&times;</span>
          </div>

            </div>
           

          <div v-else>
              <b-form-input class="align-text-bottom line-height-1 w-50" v-model="filter.newName"></b-form-input>
              <div v-if="filter.title!='Archived'" class="close-button" @click="saveEditedStatus(filter)" style="cursor: pointer; position: absolute; top: 14px; right: 5px;">
                <feather-icon icon="CheckIcon" style="color:green" size="20" />
          </div>
            </div>
          </b-list-group-item>
      </b-list-group>

        <div class="px-2 mt-2 d-flex justify-content-end" v-b-modal.modal-create-status style="cursor:pointer">
              <h6 class="add-status-label mb-1 mt-50"><feather-icon icon="PlusIcon" size="18" class="mr-75" /> Add Status</h6>
            </div> 

    </b-modal>


    <b-modal id="modal-move-status" ref="modal-move-status" cancel-variant="outline-secondary" ok-title="Save" :ok-disabled="moveToSection==null" @ok="handleUpdateGroupDates"  :no-close-on-backdrop="true" centered>
                <template #modal-header="{ close }">
                    <span>Select Group Dates</span>
                </template>
                <b-form-group :state="moveToSection? true:false" invalid-feedback="New Status field is required" label-for="newStatus">


                <v-select
                id="newStatus"
                    v-model="moveToSection"
                    :options="leadStatuses.filter((e) => e.title != removeStatusName)"
                    label="title"
                    :reduce="(val) => val.title"
                    class="small-v-select"
                    :clearable="false"
                    placeholder="Choose a new Status"
                  ></v-select>
                </b-form-group>

            </b-modal>

  </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,

    //Component
    AppColorPaletteVue,
    // Form Validation

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    return {
      required,
      sectionName: null,

      selectedTextColor: '',
      selectedBgColor: '',

      perfectScrollbarSettings,
      moveToSection:null,
      removeStatusName:null,
 
    };
  },

  created() {},

  props: {
    leadStatuses: Array,
  },

  methods: {
 


    handleUpdateGroupDates(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
        },


        editStatus(status){
        status.newName = status.title
        status.editing=true
      },

      saveEditedStatus(status){

        const cleanedStatusName = this.cleanAndNormalizeText(status.newName);
      const isDuplicate = this.leadStatuses.some((status) => this.cleanAndNormalizeText(status.title) === cleanedStatusName);
      if (isDuplicate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            text: 'Status with the same name already exists.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        return;
      } else {
        store
          .dispatch('lead/updateStatusName', { id:status.id, newName:status.newName })
          .then((response) => {
            if(response.data=='ok'){
              status.title=status.newName

            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Status was updated successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
         
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
      status.editing=false


      },





    removeStatus(section) {
      this.removeStatusName = section.title;

            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, remove the status!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
              if(result.value){
                this.$bvModal
                        .msgBoxConfirm("Would you like to move the leads to another status? (Otherwise, the status will be changed to 'Chasing'.)", {
                            title: "Please Confirm",
                            size: "sm",
                            okVariant: "primary",
                            okTitle: "Yes",
                            cancelTitle: "No",
                            cancelVariant: "outline-secondary",

                            hideHeaderClose: false,
                            noCloseOnBackdrop: true,
                            noCloseOnEsc: true,
                            centered: true,
                        })
                        .then((value) => {
                            if (value != null) {
                                if (value) {
                                    
                                    this.$bvModal.show("modal-move-status");

                                    const modalPromise = new Promise((resolve) => {
                                        this.$refs["modal-move-status"].$once("ok", () => {
                                            resolve();
                                        });
                                    });

                                    modalPromise.then(() => {
                                      

                                      if(this.moveToSection!=null){
                                        store
                                        .dispatch('lead/removeStatus', {status:section.title,newStatus:this.moveToSection})
                                        .then((res) => {

                                          this.$toast({
                                                    component: ToastificationContent,
                                                    props: {
                                                      title: 'Success',
                                                      text: 'Status has been removed successfully! 👍',
                                                      icon: 'CheckCircleIcon',
                                                      variant: 'success',
                                                    },
                                                  });
                                                  
                                                  this.newSection = null;
                                                  this.removeStatusName = null;
                                                  this.$emit('status-added');
                                                  this.$bvModal.hide("modal-move-status");



                                        })
                                        .catch((error) => {
                                          this.$toast({
                                            component: ToastificationContent,
                                            props: {
                                              title: 'Error',
                                              text: '❌ There was an error removing the status. Please try again or report an issue to support.',
                                              icon: 'XIcon',
                                              variant: 'danger',
                                            },
                                          });
                                          this.loading = false;
                                          console.log(error);
                                        });

                                      }
                                     
                            

                                    });

                                    
                                } else {
                                    this.loading = true;
                                    



                                    store
                                        .dispatch('lead/removeStatus', {status:section.title,newStatus:null})
                                        .then((res) => {

                                          this.newSection = null;
                                          this.removeStatusName = null;
                                        this.$bvModal.hide("modal-move-status");


                                        this.$emit('status-added');
                                  
                                

                                        
                                        this.$toast({
                                                    component: ToastificationContent,
                                                    props: {
                                                      title: 'Success',
                                                      text: 'Status has been removed successfully! 👍',
                                                      icon: 'CheckCircleIcon',
                                                      variant: 'success',
                                                    },
                                                  });
                                                  






                                        })
                                        .catch((error) => {
                                          this.$toast({
                                            component: ToastificationContent,
                                            props: {
                                              title: 'Error',
                                              text: '❌ There was an error removing the status. Please try again or report an issue to support.',
                                              icon: 'XIcon',
                                              variant: 'danger',
                                            },
                                          });
                                          this.loading = false;
                                          console.log(error);
                                        });

                                      


                                    
                                }
                            }

                            this.loading = false;
                            this.$bvModal.hide("modal-move-status");
                            this.$emit('status-added');
              


                        });

              }else{
                this.$bvModal.hide("modal-move-status");
              }
                    
         
            });
        },


    


    colorSelectedHandler(textColor, bgColor) {
      this.selectedTextColor = textColor;
      this.selectedBgColor = bgColor;
    },

    cleanAndNormalizeText(text) {
      return text
        .replace(/\s+/g, ' ')
        .trim()
        .toLowerCase(); // Boşlukları düzenle ve küçük harfe çevir
    },
  },
};
</script>

<style scoped>
.add-section-label {
  color: rgb(148, 148, 148);
}

.add-section-label:hover {
  color: rgb(88, 88, 88);
}

.all-section-label {
  color: #4e5154;
}

.all-section-label:hover {
  color: #73c0d2;
}

.list-group-item {
  transition: all 1s;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

