<template>
      
        <div class="radio-option">
          
    <b-form-radio :value="value"> <img :src="logoSrc" alt="Logo" class="mr-2 logo" /> {{ text }}</b-form-radio>
    
  </div>
 
  </template>
  
  <script>
  export default {
    props: {
      value: String,
      text: String,
      logoSrc: String,
    },
  };
  </script>

<style scoped>

.logo{
    height: 25px;
    width: 25px;
}
.radio-option {
  display: flex;          /* Use flexbox to align items horizontally */
  align-items: center;    /* Vertically center the items */
  margin-bottom: 10px;    /* Add spacing between options (adjust as needed) */
}
</style>